import React from "react";

const About = () => {
  return (
    <section className="py-5">
      <div className="container">
        <h2>Jacquelin Doran</h2>
      </div>
    </section>
  );
};

export default About;