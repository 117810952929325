import React from "react";

const Blog = () => {
  return (
    <section className="bg-light py-5">
      <div className="container">
        <h2>BLOG Section</h2>
      </div>
    </section>
  );
};

export default Blog;