import React from "react";
import "./footer.css";

const Footer = () => {
return(
    <footer>
        {/* Social links */}
        <div className="socials-container">
        <a href="" className="socails">
        <img src="" alt="FB"/>
        </a>
        <a href="">
        <img src="" alt="FB"/>
        </a>
        </div>
    </footer>
);
};

export default Footer;